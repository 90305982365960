import { ListItemIcon, ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import { Avatar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import md5 from "md5";
import * as React from "react";
import { useEffect, useState } from "react";
import { getMenuConfig } from "../api/getConfig";
import menuIcon from "../icons/menu.svg";
import questionIcon from "../icons/question.svg";
import settingIcon from "../icons/settings.svg";
const useStyles = makeStyles(() => ({
  navlinks: {
    background: "#1B003D",
    color: "#ffff",
  },
  switchApp: {
    padding: "24px",
  },
}));

/**
 *
 * @returns {JSX.Element} JSX
 */
function TopNavigation() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNavigation = (event, path) => {
    setAnchorEl(null);
    window.location.href = `https://${window.location.host}${path}`;
  };
  const [user, setUser] = useState({
    name: "",
    email: "",
  });
  const [gravatarProfile, setGravatarProfile] = useState("");
  const [data, setData] = useState([]);
  const [tenantName, setTenantName] = useState("");
  const [settingsUrl, setSettingsUrl] = useState(null);
  const [helpUrl, setHelpUrl] = useState(null);

  useEffect(() => {
    setTenantName("Energise");
    const fetchData = async () => {
      try {
        const auth = await System.import("@gentrack/frontend-auth");
        const user = await auth.getUser();
        setUser(await auth.getUser());
        const srcUrl = md5(user.email);
        setGravatarProfile(`https://www.gravatar.com/avatar/${srcUrl}.jpg`);
        const response = await getMenuConfig("SANDBOX-h2k5ejdc8wIZ8AhVYJVaM");
        setData(response.menuConfig);
        setTenantName("Energise");
        setSettingsUrl(response.settingsUrl);
        setHelpUrl(response.helpUrl);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar className={classes.navlinks}>
            <IconButton onClick={handleClick} data-testid="menuIconTestId">
              <img src={menuIcon} />
            </IconButton>

            <Typography data-testid="tenantName" variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {tenantName}
            </Typography>
            {settingsUrl && (
              <IconButton
                data-testid="settingIconId"
                size="large"
                color="inherit"
                onClick={(event) => handleNavigation(event, settingsUrl)}
              >
                <img src={settingsUrl ? settingIcon : null} />
              </IconButton>
            )}
            {helpUrl && (
              <IconButton
                data-testid="helpIconTestId"
                size="large"
                color="inherit"
                onClick={(event) => handleNavigation(event, helpUrl)}
              >
                <img src={helpUrl ? questionIcon : null} />
              </IconButton>
            )}
            <Box display="flex" alignItems="center">
              <Typography data-testid="authUserName">{user.name}</Typography>
              <Box
                role="button"
                aria-label="account-icon"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={48}
                width={48}
                lineHeight={1}
              >
                <IconButton>
                  <Avatar alt="" src={gravatarProfile} />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem key="switchTo">
          <b>Switch to</b>
        </MenuItem>

        {data.map((menu) => (
          <MenuItem
            data-testid={`selected-${menu.name}`}
            key={menu.name}
            onClick={(event) => handleNavigation(event, menu.path)}
          >
            <ListItemIcon>
              <Icon>
                <img src={menu.iconUrl} />
              </Icon>
            </ListItemIcon>
            <Tooltip title={menu.description} arrow>
              <ListItemText primary={menu.name} />
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default TopNavigation;
