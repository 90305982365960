import { BrowserRouter as Router } from "react-router-dom";
import TopNavigation from "./src/components/top-navigation";

/**
 *
 * @returns {JSX.Element} main jsx element
 */
export default function App() {
  return (
    <Router>
      <TopNavigation />
    </Router>
  );
}
