import axios from "axios";
export const { REACT_APP_API_GATEWAY_URL } = process.env;
export const getApiUrl = () => {
  return process.env.REACT_APP_ENDPOINT;
};
const baseURL = new URL("/v1", getApiUrl()).href;
export type MenuConfig = {
  url: string;
  name: string;
  pathRoute: string;
  description?: string;
  iconUrl?: string;
};
interface TopNavConfigResponse {
  logoUrl?: string;
  helpUrl?: string;
  settingsUrl?: string;
  topNavUrl?: string;
  tenantName?: string;
  menuConfig: MenuConfig[];
}
export const getMenuConfig = async (tenantId: string): Promise<TopNavConfigResponse> => {
  const response = await axios.get(`${baseURL}/top-nav/config/${tenantId}`);
  const menuConfigResponse: TopNavConfigResponse = response.data;
  return menuConfigResponse;
};
export const authUser = {
  header: {
    alg: "RS256",
    typ: "JWT",
    kid: "rRcQ2mP7Yvk-EWFDuKy7H",
  },
  payload: {
    nickname: "Vishal",
    name: "Vishal Teli",
    email: "vishal.teli@gentrack.com",
    picture:
      "https://s.gravatar.com/avatar/8d52d1c120de01d2efd1aa7543e7f8da?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fvt.png",
    updated_at: "2022-05-09T23:07:31.023Z",
    iss: "https://gentrack-dev.au.auth0.com/",
    sub: "waad|rBCMQnXZcIk7KtWgfPMT7GajtYn8JiJUKiTIdUkzyDE",
    aud: "GHEiFJjOL0LC7qC3pnj7mJZRMLpoFMhg",
    iat: 1652137652,
    exp: 1652173652,
    nonce: "YTVCeThtRk4yY3ZISkU1V3RXTW8wVzRqUzZFYzFZMGVqQjFKWkZqZzJ6cw==",
    org_id: "org_ZlZD9JOKJlXrYAab",
  },
  signature:
    "kCQ-uib5FKZcp88UcuvJxX_YvgHY_dAPLABz11WR5JWHOsms9DsTVzrgDHNVEa1C8m35dZxh1oyRyDbRgZdKZBAdBH3vMmT2aabKPwyqDyo-k0JbV4IyTxmy6WKTcn12poP5FtUhQo4CTdxzNyGToYCQEB2uyYNj4hX_l0rCzAjYPKi_z30k2pRiIAHD8Gg_bSKnYh1XAOCvFlIUemX4qifiY2DMQoTQHhf2SecyZfXEbXnbjKpYODi6K6FqpsmbM54Gr5j7gITtMd7LrdV55LSs5wvBJjXuNIPOCQ94MqpFcQ_gErlcLA6oVwSust8UaLvTm04MeWov8HAYJXq3XA",
};
